
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































































































































































































.table-content__data {
  width: 100%;
  font-family: $ff-alt;
  border-top: 1px solid $c-light-gray;
  border-collapse: collapse;
  border-spacing: 0;

  @include mq(xl) {
    border: 0;
  }

  [class*='table-desktop--solo'] & {
    width: 100%;
  }
}

.table-content__data__rgu {
  @extend %text-uppercase;
  @extend %fw-black;

  display: block;
  width: 100%;
  padding-top: $spacing;
  padding-bottom: $spacing;
  color: $c-pink-medium;
  font-size: 1.8rem;

  @include mq(xl) {
    padding-right: calc(68% + 3rem);
    text-align: right;

    .is-solo & {
      padding-right: 71%;
    }

    [class*='table-desktop--solo'] & {
      padding-right: 38rem;
    }
  }
}

.table-content__data__spec-outer {
  display: flex;
  align-items: center;
  @include mq($until: xl) {
    flex-wrap: wrap;
  }

  @include mq(xl) {
    &:nth-child(odd) {
      width: 100%;
      background: rgba(200, 200, 200, 0.1);
    }
  }
}

.table-content__data__spec,
.table-content__data__value {
  width: 50%;
}

.table-content__data__spec {
  @extend %fw-normal;

  display: block;
  width: 100%;
  padding-top: $spacing / 2;
  padding-right: 3rem;
  padding-bottom: $spacing / 2;
  line-height: 1.6;
  text-align: left;

  @include mq(xl) {
    width: 32%;
    padding-left: $spacing;
    text-align: right;

    .is-solo & {
      width: 31%;
    }

    [class*='table-desktop--solo'] & {
      width: calc(100% - 35rem);
    }

    span {
      text-transform: uppercase;
    }
  }

  small {
    display: block;
    opacity: 0.5;
  }
}

.table-content__data__value {
  @extend %text-center;
  @extend %fw-bold;

  display: block;
  width: 34%;
  padding: $spacing / 2;
  text-align: center;

  [class*='table-desktop--'][class*='--solo'] & {
    width: 32%;
  }

  @include mq($until: xl) {
    width: 49%;

    [class*='table-desktop--'][class*='--solo'] & {
      width: 100%;
    }
  }

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 23%;
    }
  }

  @include mq(xl) {
    [class*='table-desktop--'][class*='--solo'] & {
      width: 35rem;
    }
  }

  & + & {
    display: block;
    margin-left: 1rem;
  }

  @include mq($until: xl) {
    background: rgba(200, 200, 200, 0.1);

    & + & {
      display: block;
      margin-left: 2%;
    }
  }

  small {
    display: block;
    opacity: 0.5;
  }
}

legend,
th {
  line-height: 4rem;
}

td {
  padding: 0;
}

table table {
  border-collapse: collapse;
}
