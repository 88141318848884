
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































.table-content__data__value {
  width: 50%;
}

.table-content__data__value {
  @extend %text-center;
  @extend %fw-bold;

  display: block;
  width: 34%;
  padding: $spacing / 2;
  text-align: center;

  [class*='table-desktop--'][class*='--solo'] & {
    width: 32%;
  }

  @include mq($until: xl) {
    width: 49%;

    [class*='table-desktop--'][class*='--solo'] & {
      width: 100%;
    }
  }

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 23%;
    }
  }

  @include mq(xl) {
    [class*='table-desktop--'][class*='--solo'] & {
      width: 35rem;
    }
  }

  & + & {
    display: block;
    margin-left: 1rem;
  }

  @include mq($until: xl) {
    background: rgba(200, 200, 200, 0.1);

    & + & {
      display: block;
      margin-left: 2%;
    }
  }

  small {
    display: block;
    opacity: 0.5;
  }
}
